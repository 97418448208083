/*
    DEMO STYLE
*/

@import "https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700";

body {
    font-family: 'Inter', sans-serif !important;
    background: #fafafa;
}

p {
    font-family: 'Inter', sans-serif !important;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

/* Style the video: 100% width and height to cover the entire window */
#myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

.btn-primary {
    color: white !important;
}

i,
span {
    display: inline-block;
}

/* ---------------------------------------------------
    new
----------------------------------------------------- */

#sideNav {
    padding:20px;
    width: 120px;
}
#sideNav .nav-item {
    display:block;
    margin-bottom: 10px;
}
#sideNav .nav-group img {
    width: 40px !important;
    height: 40px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
#sideNav .sideNavLinks {
    padding-top:20px !important;
}
#sideNav .sideNavLinks .active {
    box-sizing: border-box;
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    box-shadow: 4px 8px 40px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    flex: none;
    order: 1;
    flex-grow: 0;
    display: block !important;
}
#sideNavFooter ul {
    list-style-type: none;
    padding: 0;
}
#sideNavFooter li {
    padding-top:16px;
}
#sideNavFooter .p3 {
    padding: 0 !important;
}
#sideNavFooter .rounded-circle {
    width: 32px !important;
    height: 32px !important;
}
.sidebar-header {
    padding:0 !important
}
.col {
    padding-left: 1.5em /* AA change */
}
#mainSearch .form-control, .modal-header .form-control {
    padding-left:16px !important;
}
.modal-header .form-control {
    margin-right:16px !important;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    align-items: stretch;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: white;
    color: #fff;
    transition: all 0.3s;
}

#sidebar.active {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
}

#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
    display: none;
}

#sidebar.active .sidebar-header strong {
    display: block;
}

#sidebar ul li a {
    text-align: left;
}

#sidebar a {
    text-decoration: none;
    color: #3A3E44 !important;

}

#sidebar.active ul li a {
    padding: 20px 10px;
    text-align: center;
    font-size: 0.85em;
}

#sidebar.active ul li a i {
    margin-right: 0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
}

#sidebar.active ul ul a {
    padding: 10px !important;
}

#sidebar.active .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
}

#sidebar .sidebar-header {
    padding: 20px;
    background: white;
}

#sidebar .sidebar-header strong {
    display: none;
    font-size: 1.8em;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}



#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    margin-bottom: 16px;
}

#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}


#sidebar ul li a i {
    margin-right: 10px;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: white;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

.btn-primary {
    color: "white" !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

.drop-zone {
    width: 100% !important;
    padding-right: 32px;
    height: 320px !important;
}

#content {
    width: 100%;
    padding-top: 20px; /* AA change */
    padding-bottom: 20px; /* AA change */
    min-height: 100vh;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        margin-left: -80px !important;
    }

    .dropdown-toggle::after {
        top: auto;
        bottom: 10px;
        right: 50%;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }

    #sidebar.active {
        margin-left: 0 !important;
    }

    #sidebar .sidebar-header h3,
    #sidebar .CTAs {
        display: none;
    }

    #sidebar .sidebar-header strong {
        display: block;
    }

    #sidebar ul li a {
        padding: 20px 10px;
    }

    #sidebar ul li a span {
        font-size: 0.85em;
    }

    #sidebar ul li a i {
        margin-right: 0;
        display: block;
    }

    #sidebar ul ul a {
        padding: 10px !important;
    }

    #sidebar ul li a i {
        font-size: 1.3em;
    }

    #sidebar {
        margin-left: 0;
    }

    #sidebarCollapse span {
        display: none;
    }
}


.mk-map-view {
    border-radius: 16px;
}

.pinListContainer {
    width: 390px !important;
    overflow-x: "none" !important;

}



.chooserPinList {
    /*FireFox*/
    scrollbar-width: none;
    /*IE10+*/
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.chooserPinList::-webkit-scrollbar {
    /*Chrome, Safari, Edge*/
    display: none;
}

.pinList {
    max-width: 100%;
    overflow-x: hidden;

    overflow-y: auto;
    top: 60px;
    bottom: 20px;
    position: fixed;
    width: 400px;
    scrollbar-width: none;
    /* Firefox */

}

.pinListAdd {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: max-content;
    position: fixed;
}

.collectionList {
    overflow-y: auto;
    top: 60px;
    bottom: 20px;
    position: fixed;
}

.pinListTags {
    overflow-y: auto;
    /* top: 20px; */
    /* bottom: 20px;
    position: fixed; */
}

.title {
    font-size: 24px;
    font-weight: 700;
    color: #3A3E44;
}

.subtitle {
    font-size: 20px;
    font-weight: 400;
    color: #3A3E44;
}

.alertbody {
    font-size: 16px;
    font-weight: 400;
    color: rgba(58, 62, 68, 0.6)
}

.tagListPinCard::-webkit-scrollbar {
    width: 0 !important
}

.tagListPinCard {
    overflow: -moz-scrollbars-none;
}


.pinText {
    font-size: 16px;
    font-weight: 400;
    color: #3A3E44;
}

.secondaryText {
    font-size: 16px;
    font-weight: 700;
    color: rgba(58, 62, 68, 0.6);
    ;
}

.placeResultTitle {
    font-size: 17px;
    font-weight: 700;
    color: #3A3E44;
}

.placeResultSubtitle {
    font-size: 13px;
    font-weight: 400;
    color: rgba(58, 62, 68, 0.6);
    ;
}

.searchResult {
    padding-bottom: 16px;
}

.searchSectionTitle {
    font-size: 13px;
    line-height: 20px;
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 600;
    color: #3A3E44;
    background-color: rgba(0, 0, 0, 0.04);
}

.pinDescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    color: #3A3E44;
}

.unsplash-react {
    width: 100%;
    height: 290px !important;
}

.form-check-input[type=checkbox] {
    width: 25px;
    height: 25px;
    border: 1px solid #3A3E44;
}

.formInput {
    border-radius: 16px;
    height: 64px;
    font-size: 16;
    font-weight: 400;
    color: rgba(58, 62, 68, 0.6);
}

.transButton {
    box-sizing: border-box;

    /* Auto layout */


    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;

    /* width: 171px;
height: 44px; */

    background: rgba(255, 255, 255, 0.01);
    /* Tonal/White50 */

    border: 1px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(16px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 1;

}

.modal-90w {
    width: 90% !important;
    max-width: 90% !important;
}

.modal-70w {
    width: 70% !important;
    max-width: 70% !important;
}

.modal-50w {
    width: 50% !important;
    max-width: 50% !important;
}

#custom-switch {
    width: 50px !important;
    height: 30px !important;
}

.form-check-label {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #3A3E44 !important;
    padding-left: 10px !important;
    padding-top: 6px;
}

.form-label {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;

    color: rgba(58, 62, 68, 0.6);
}

.rbt-input-main {
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(58, 62, 68, 0.6);
}

.formLabelSwitch {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;

}

.offcanvas {
    border-left-width: 0px !important;
}




.react-tags {
    position: relative;
    padding: 10px 0 0 10px;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    min-height: 60px;
    /* shared font styles */
    font-size: 1em;
    line-height: 1.2;

    /* clicking anywhere will focus the input */
    cursor: text;
}

.react-tags.is-focused {
    border-color: #B1B1B1;
}

.react-tags__selected {
    display: inline;
}

.react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    padding: 6px 8px;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    background: #F1F1F1;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
}

.react-tags__selected-tag:after {
    content: '\2715';
    color: #AAA;
    margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
    border-color: #B1B1B1;
}

.react-tags__search {
    display: inline-block;

    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;

    /* prevent autoresize overflowing the container */
    max-width: 100%;
}

@media screen and (min-width: 30em) {

    .react-tags__search {
        /* this will become the offsetParent for suggestions */
        position: relative;
    }

}

.react-tags__search-input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;

    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
}

.react-tags__search-input::-ms-clear {
    display: none;
}

.react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
}

@media screen and (min-width: 30em) {

    .react-tags__suggestions {
        width: 240px;
        z-index: 999;
    }

}

.react-tags__suggestions ul {
    margin: 4px -1px;
    padding-left: 0;
    list-style: none;
    background: white;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {

    padding: 12px 12px;
    font-weight: 400;
    font-size: 16px;

}

.react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
    padding-right: 0px;
}

.react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
}

.react-tags__suggestions li.is-active {
    background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
}

.blurBackground {
    backdrop-filter: blur(16px);
}